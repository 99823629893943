import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted, getCurrentInstance,provide} from 'vue';
const CxCardHelper = defineComponent({
    name:'CxCard',
    title:'车型',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            refMap:new Map(),
            showUpload:false,
            uploadParams:{
                listType:'picture-card',
                showFileList:true,
                showInDialog:true,
                accept:'.jpg,.jpeg,.png,.JPG,.JPEG,.PBG',
                uploadType:'cx'
            },
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: false,
                modelPath: "/cx"
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                name: [
                    {required: true, message: "请输入车型名称", trigger: "blur" }
                ],
                capacity: [
                    {required: true, message: "请选择车辆载重", trigger: "blur" }
                ],
                size: [
                    {required: true, message: "请选择车辆体积", trigger: "blur" }
                ],
                length: [
                    {required: true, message: "请选择车长", trigger: "blur" }
                ],
                width: [
                    {required: true, message: "请选择车宽", trigger: "blur" }
                ],
                height: [
                    {required: true, message: "请选择车高", trigger: "blur" }
                ]
            }
        })
        onMounted(()=>{
        })
        //---------------------------computed---------------------------
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            if('/load'==addOrLoad)dataObj.showUpload=true;
            loadUploadFiles('uploadComp',data.data.uploadFileBeanList);
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            if(dataObj.form.capacity==0 || dataObj.form.size==0 || dataObj.form.length==0 || dataObj.form.width==0 || dataObj.form.height==0){
                proxy.$message('车辆属性不能为0哦');
                return false;
            }
            return true;
        }
        //查看详情的时候，为上传组件赋值
        const loadUploadFiles=(uploadType,files)=>{
            if(files){
                const aloneServerInfo=utils.$$str.decrypt(sessionStorage.getItem("aloneServerInfo"));
                dataObj.refMap.get(uploadType).fileList=[];//要先清空，否则重载的时候要重复
                dataObj.refMap.get(uploadType).dialogImageUrls=[];
                files.forEach((item)=>{
                    let path=item.path.substr(item.path.indexOf('/otherFiles'));
                    path=aloneServerInfo+path;
                    dataObj.refMap.get(uploadType).fileList.push({name:item.preName,url:path,id:item.id});
                    dataObj.refMap.get(uploadType).dialogImageUrls.push(path);
                })
            }
        }
        //上传的时候，增加参数传入到后台
        const buildUploadParams=(formData)=>{
            formData.append('belongMaxId',dataObj.form.id);
            // formData.append('belongMinId',dataObj.form.id);
        }
        //上传成功之后，需要把本次上传的图片文件追加到上传控件文件数组中，
        const afterResult=(res,uploadInst)=>{
            let uploadArr=[];
            res.data.forEach((item,index)=>{
                uploadArr.push({name:item.preName,url:item.fileUrl,id:item.id});
                uploadInst.dialogImageUrls.push(item.fileUrl);//设置点击查看上传图片详情的时候，图片组件可以显示的图片地址集合
            })
            uploadInst.fileList=uploadInst.fileList.concat(uploadArr);
        }

        return{
            ...toRefs(dataObj),beforeOpen,beforeSaveHandler,loadUploadFiles,buildUploadParams,afterResult
         
        }
    }
});
export default CxCardHelper;
<template>
    <div class="Cx">
        <el-form ref="formRef" :model="form" :rules="rules" label-width="100px">
            <el-form-item label="车型名称" prop="name">
                <el-input @input="e => form.name = validForbid(e)" v-model="form.name" placeholder="请输入车型名称" maxlength="25" :disabled="disabled" clearable/>
            </el-form-item>
            <el-form-item label="载重(吨)" prop="capacity">
                <div style="display:flex;align-items: center;">
                    <div style="flex: 1"><el-input-number v-model="form.capacity" :min="0" :max="9999999" precision=2 :disabled="disabled" style="width: 100%" controls-position="right"/></div>
                    <div style="width: 20px">吨</div>
                </div>
            </el-form-item>
            <el-form-item label="载方(方)" prop="size">
                <div style="display:flex;align-items: center;">
                    <div style="flex: 1"> <el-input-number v-model="form.size" :min="0" :max="9999999" precision=2 :disabled="disabled" style="width: 100%" controls-position="right"/></div>
                    <div style="width: 20px">m³</div>
                </div>
            </el-form-item>
            <el-form-item label="车厢长(米)" prop="length">
                <div style="display:flex;align-items: center;">
                    <div style="flex: 1"><el-input-number v-model="form.length" :min="0" :max="9999999" precision=2 :disabled="disabled" style="width: 100%" controls-position="right"/></div>
                    <div style="width: 20px">m</div>
                </div>
            </el-form-item>
            <el-form-item label="车厢宽(米)" prop="width">
                <div style="display:flex;align-items: center;">
                    <div style="flex: 1"><el-input-number v-model="form.width" :min="0" :max="9999999" precision=2 :disabled="disabled" style="width: 100%" controls-position="right"/></div>
                    <div style="width: 20px">m</div>
                </div>
            </el-form-item>
            <el-form-item label="车厢高(米)" prop="height">
                <div style="display:flex;align-items: center;">
                    <div style="flex: 1"><el-input-number v-model="form.height" :min="0" :max="9999999" precision=2 :disabled="disabled" style="width: 100%" controls-position="right"/></div>
                    <div style="width: 20px">m</div>
                </div>
            </el-form-item>
        </el-form>

        <div v-show="showUpload"><Upload v-bind="uploadParams" :ref="el=>refMap.set('uploadComp',el)"></Upload></div>
    </div>
</template>


<script>
    import CxCardHelper from "./CxCardHelper.js";
    export default CxCardHelper;
</script>

<style scoped>
    .Cx{
        width: 100%;
    }
</style>
